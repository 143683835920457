import React from 'react'
import PropTypes from 'prop-types'

const TeamItem = (props) => {
  const { employee } = props
  return (
    <div className='employee'>
      {employee.acf.image && (
        <img
          className='employee__image'
          src={employee.acf.image.sizes.medium}
          width={employee.acf.image.sizes['medium-width']}
          height={employee.acf.image.sizes['medium-height']}
          alt={employee.acf.details.name}
          title={employee.acf.details.name}
        />
      )}
      <h2 className='employee__name'>{employee.acf.details.name}</h2>
      {employee.acf.details.description && (
        <p className='employee__description'>{employee.acf.details.description}</p>
      )}

      {employee.acf.details.phone && (
        <div className='employee__link'>
          <a
            className='link--phone'
            href={`tel:${employee.acf.details.phone}`}
            target='_blank'
            rel='noopener noreferrer'
            title={`${employee.acf.details.name} anrufen`}>
            <span>{employee.acf.details.phone}</span>
          </a>
        </div>
      )}

      {employee.acf.details.mobile && (
        <div className='employee__link'>
          <a
            className='link--phone'
            href={`tel:${employee.acf.details.mobile}`}
            target='_blank'
            rel='noopener noreferrer'
            title={`${employee.acf.details.name} anrufen`}>
            <span>{employee.acf.details.mobile}</span>
          </a>
        </div>
      )}

      {employee.acf.details.mail && (
        <div className='employee__link'>
          <a
            className='link--mail'
            href={`mailto:${employee.acf.details.mail}`}
            target='_blank'
            rel='noopener noreferrer'
            title={`${employee.acf.details.name} anrufen`}>
            <span>{employee.acf.details.mail}</span>
          </a>
        </div>
      )}
    </div>
  )
}

TeamItem.propTypes = {
  employee: PropTypes.object.isRequired
}

export default TeamItem
