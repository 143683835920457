import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'

import 'isomorphic-fetch'

import Error from './helpers/Error'
import Loader from './helpers/Loader'
import TeamList from './TeamList'

const TeamOverview = () => {
  const [hasError, setErrors] = useState(false)
  const [isLoadingEmployees, setLoadingEmployees] = useState(true)
  const [team, setTeam] = useState()
  const [filter, setFilter] = useState(false)

  const loadEmployees = () => {
    fetch('/wp-json/wp/v2/employee/?per_page=100&orderby=menu_order&order=asc')
      .then((response) => response.json())
      .then((data) => {
        setTeam(data)
        setLoadingEmployees(false)
      })
      .catch((error) => setErrors(error))
  }

  const checkURL = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const urlFilter = urlParams.get('kategorie')

    if (urlFilter) {
      window.history.replaceState({}, document.title, '/team')
      const filterButtons = [...document.querySelectorAll('.team-filter-button')]
      filterButtons.forEach((button) => {
        button.classList.remove('active')
      })
      const activeFilterButtons = document.querySelectorAll(`[data-category-slug='${urlFilter}']`)
      activeFilterButtons.forEach((button) => {
        button.classList.add('active')
        const filterAttribute = button.getAttribute('data-category')
        if (filterAttribute !== false && filterAttribute !== 'false') {
          const teamCategory = parseInt(filterAttribute, 10)
          setFilter(teamCategory)
        } else {
          setFilter(false)
        }
      })
    }
  }

  const handleFilterButtonClick = useCallback((event) => {
    const { target } = event
    const filterButtons = [...document.querySelectorAll('.team-filter-button')]
    filterButtons.forEach((button) => {
      button.classList.remove('active')
    })
    target.classList.add('active')
    const filterAttribute = target.getAttribute('data-category')
    if (filterAttribute !== false && filterAttribute !== 'false') {
      const teamCategory = parseInt(filterAttribute, 10)
      setFilter(teamCategory)
    } else {
      setFilter(false)
    }
    event.preventDefault()
  }, [])

  useEffect(() => {
    loadEmployees()
    checkURL()

    const filterButtons = [...document.querySelectorAll('.team-filter-button')]
    filterButtons.forEach((button) => {
      button.addEventListener('click', handleFilterButtonClick)
    })

    return () => {
      filterButtons.forEach((button) => {
        button.removeEventListener('click', handleFilterButtonClick)
      })
    }
  }, [handleFilterButtonClick])

  return (
    <>
      {hasError && <Error />}
      {isLoadingEmployees ? (
        <Loader />
      ) : (
        <>
          <TeamList team={team} filter={filter} />
        </>
      )}
    </>
  )
}

const domContainer = document.getElementById('teamOverview')
if (domContainer) {
  ReactDOM.render(<TeamOverview />, domContainer)
}
