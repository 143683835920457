import React from 'react'

const Loader = () => (
  <div className='bouncing-loader'>
    <div className='bouncing-loader__dot' />
    <div className='bouncing-loader__dot' />
    <div className='bouncing-loader__dot' />
  </div>
)

export default Loader
