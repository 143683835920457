import React from 'react'
import PropTypes from 'prop-types'

import TeamItem from './TeamItem'

const TeamList = (props) => {
  const { team, filter } = props
  let teamToDisplay = team
  if (filter !== false) {
    teamToDisplay = team.filter((employee) => employee.acf.details.category.indexOf(filter) !== -1)
  }

  return (
    <>
      {teamToDisplay.map((employee) => (
        <React.Fragment key={employee.id}>
          <TeamItem key={employee.id} employee={employee} className='employee' />
        </React.Fragment>
      ))}
    </>
  )
}

TeamList.propTypes = {
  team: PropTypes.array.isRequired,
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

TeamList.defaultProps = {
  filter: false
}

export default TeamList
